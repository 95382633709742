import React from 'react';
import DataTableLib from 'react-data-table-component';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const StyledWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'overflow',
})(({ theme, overflow }) => ({
  fontFamily: theme.typography.fontFamily,
  '.rdt_Table': {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    ...(overflow !== false && {
      maxHeight: '500px',
      overflowY: 'auto',
    }),
  },
  '.rdt_TableCol': {
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  fontWeight: 600,
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& svg': {
    fontSize: '.9rem', // smaller sort icon
    marginLeft: theme.spacing(0.5),
  },
},  
  '.rdt_TableHead': {
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontWeight: 600,
    fontSize: '0.875rem',
    minHeight: '48px',
  },
  '.rdt_TableRow': {
    minHeight: '48px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '.rdt_TableCell': {
    padding: theme.spacing(1.5),
    fontSize: '0.875rem',
  },
  '.rdt_Pagination': {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
}));

const DataTable = ({
  rows,
  columns,
  title = '',
  filename = 'data',
  rowsPerPage = 100,
  rowsPerPageOptions = [20,50,100,300,500],
  overflow = true,
}) => {
  const formattedColumns = columns.map((col) => {
    const sampleValue = rows?.[0]?.[col.field];
  
    const isNumeric = typeof sampleValue === 'number';
    const isDate = sampleValue && !isNumeric && !isNaN(new Date(sampleValue));
  
    return {
      name: col.headerName || col.field,
      selector: (row) => row[col.field],
      sortable: true,
      sortFunction: (a, b) => {
        const valA = a[col.field];
        const valB = b[col.field];
  
        // Handle nulls and undefineds
        if (valA == null && valB == null) return 0;
        if (valA == null) return 1;
        if (valB == null) return -1;
  
        if (isNumeric) {
          return Number(valA) - Number(valB);
        }
  
        if (isDate) {
          return new Date(valA) - new Date(valB);
        }
  
        // String fallback
        return String(valA).localeCompare(String(valB));
      },
      cell: (row) =>
        col.formatter ? col.formatter(row[col.field], row) : row[col.field],
      wrap: true,
      grow: 1,
      center: false,
    };
  });
  
  

  const handleCSVExport = () => {
    if (!rows || !rows.length) return;

    const csvHeader = formattedColumns.map((col) => col.name).join(',');
    const csvRows = rows.map((row) =>
      formattedColumns
        .map((col) => {
          const raw = row[col.selector(row)];
          const value = col.cell ? col.cell(row) : raw;
          const clean =
            typeof value === 'string' ? value.replace(/"/g, '""') : value;
          return `"${clean ?? ''}"`;
        })
        .join(',')
    );

    const csvContent = [csvHeader, ...csvRows].join('\n');
    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${filename || title || 'data'}.csv`;
    link.click();
  };

  return (
    <StyledWrapper overflow={overflow}>
      <DataTableLib
        title=""
        columns={formattedColumns}
        data={rows}
        pagination
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={rowsPerPageOptions}
        highlightOnHover
        dense
        responsive
        persistTableHead
        defaultSortFieldId={1}
        actions={
          <Button variant="outlined" size="small" onClick={handleCSVExport}>
            Export CSV
          </Button>
        }
      />
    </StyledWrapper>
  );
};

export default DataTable;
