import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Grid, Tabs, Tab, Box, CircularProgress } from '@mui/material';
import { req } from '../../utils';
import DataTable from '../DataTable';

export const OffContractService = ({ filterProps }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    field: null,
    direction: null
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await req('get', 'get_off_contract_servicing');
        setData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = ({ children, value, index }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );

  const bin_collections_without_contract_columns = [
    { field: 'myob_record_id', headerName: 'MYOB', width: 70 },
    { 
      field: 'customer_name', 
      headerName: 'Customer', 
      width: 200,
      formatter: (value, row) => {
        return value ? value.toUpperCase() : '';
      }
    },
    { 
      field: 'nbr_of_bins', 
      headerName: 'Bins', 
      width: 150,
      type: 'number',
      sortComparator: (v1, v2) => Number(v1) - Number(v2)
    },
    { 
      field: 'last_date_collected', 
      headerName: 'Last Collection', 
      width: 150,
      formatter: (value, row) => {
        return value ? new Date(value * 1000).toLocaleDateString() : '';
      }
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      formatter: (value, row) => {
        return value ? value.toUpperCase() : '';
      }
    }
  ];
  const bins_expired_contract_columns = [
    { field: 'myob_record_id', headerName: 'MYOB', width: 70 },
    { 
      field: 'customer_name', 
      headerName: 'Customer', 
      width: 200,
      formatter: (value, row) => {
        return value ? value.toUpperCase() : '';
      }
    },
    { 
      field: 'bins_collected_outside_contract', 
      headerName: 'Bins', 
      width: 150,
      type: 'number',
      sortComparator: (v1, v2) => Number(v1) - Number(v2)
    },
    { 
      field: 'last_pickup_date', 
      headerName: 'Last Collection', 
      width: 150,
      formatter: (value, row) => {
        return value ? new Date(value * 1000).toLocaleDateString() : '';
      }
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      formatter: (value, row) => {
        return value ? value.toUpperCase() : '';
      }
    }
   
  ];
  const servicing_without_contract_columns = [
    { field: 'myob_record_id', headerName: 'MYOB', width: 70 },
    { 
      field: 'account_name', 
      headerName: 'Customer', 
      width: 200,
      formatter: (value, row) => {
        return value ? value.toUpperCase() : '';
      }
    },
    { field: 'nbr_of_calls', headerName: '# Calls', width: 150 },
    { 
      field: 'last_time_completed', 
      headerName: 'Last completion', 
      width: 150,
      formatter: (value, row) => {
        return value ? new Date(value * 1000).toLocaleDateString() : '';
      }
    },
    {
      field: 'last_time_created',
      headerName: 'Last Created',
      width: 150,
      formatter: (value, row) => {
        return value ? new Date(value * 1000).toLocaleDateString() : '';
      }
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      formatter: (value, row) => {
        return value ? value.toUpperCase() : '';
      }
    }
  ];
  const servicing_expired_contract_columns = [
    { field: 'myob_record_id', headerName: 'MYOB', width: 70 },
    { 
      field: 'account_name', 
      headerName: 'Customer', 
      width: 200,
      formatter: (value, row) => {
        return value ? value.toUpperCase() : '';
      }
    },
    { field: 'nbr_of_calls', headerName: '# Calls', width: 150 },
    { 
      field: 'last_time_completed', 
      headerName: 'Last completion', 
      width: 150,
      formatter: (value, row) => {
        return value ? new Date(value * 1000).toLocaleDateString() : '';
      }
    },
    {
      field: 'last_time_created',
      headerName: 'Last Created',
      width: 150,
      formatter: (value, row) => {
        return value ? new Date(value * 1000).toLocaleDateString() : '';
      }
    },
    {
      field: 'contract_ended',
      headerName: 'Contract End Date',
      width: 150,
      formatter: (value, row) => {
        return value ? new Date(value * 1000).toLocaleDateString() : '';
      }
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      formatter: (value, row) => {
        return value ? value.toUpperCase() : '';
      }
    }
  ];

  const sortData = (rows, field, direction) => {
    if (!field) return rows;

    return [...rows].sort((a, b) => {
      let aValue = a[field];
      let bValue = b[field];

      if (['nbr_of_bins', 'bins_collected_outside_contract'].includes(field)) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      }
      else if (field.includes('date') || field.includes('time')) {
        aValue = new Date(aValue * 1000);
        bValue = new Date(bValue * 1000);
      }

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const handleSortChange = (field, direction) => {
    setSortConfig({ field, direction });
  };

  const getCurrentData = () => {
    let currentData;
    switch (tabValue) {
      case 0:
        currentData = data?.bin_collections_without_contract || [];
        break;
      case 1:
        currentData = data?.bin_collections_outside_contract || [];
        break;
      case 2:
        currentData = data?.service_without_contract || [];
        break;
      case 3:
        currentData = data?.service_calls_after_contract_end || [];
        break;
      default:
        currentData = [];
    }
    return sortConfig.field ? 
      sortData(currentData, sortConfig.field, sortConfig.direction) : 
      currentData;
  };

  if (loading) {
    return (
      <Grid item xs={12}>
        <Card >
          <CardHeader title="Off Contract Servicing" />
          <CardContent>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Card >
        <CardHeader title="Off Contract Servicing" />
        <CardContent>
          <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
            <Tab label="Bins W/O Contract" />
            <Tab label="Bins Expired Contract" />
            <Tab label="Servicing W/O  Contract" />
            <Tab label="Servicing Expired Contract" />
          </Tabs>
          <TabPanel value={tabValue} index={0} className="p-0">
            <DataTable 
              rows={getCurrentData()}
              columns={bin_collections_without_contract_columns}
              filename="bins_wo_contract"
              onSortChange={handleSortChange}
              sortConfig={sortConfig}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} className="p-0">
            <DataTable 
              rows={getCurrentData()}
              columns={bins_expired_contract_columns}
              filename="bins_expired_contract"
              onSortChange={handleSortChange}
              sortConfig={sortConfig}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2} className="p-0">
            <DataTable 
              rows={getCurrentData()}
              columns={servicing_without_contract_columns}
              filename="servicing_wo_contract"
              onSortChange={handleSortChange}
              sortConfig={sortConfig}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3} className="p-0">
            <DataTable 
              rows={getCurrentData()}
              columns={servicing_expired_contract_columns}
              filename="servicing_expired_contract"
              onSortChange={handleSortChange}
              sortConfig={sortConfig}
            />
          </TabPanel>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default OffContractService;